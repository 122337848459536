<template>
  <div class="form-wrapper" :class="formState">
    <div class="inner-wrapper">
      <div class="form">
        <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <label for="email">Email</label>
              <div class="input-field">
                <input type="email" name="email" id="email" data-rules='["required","email"]' v-model="email" />
              </div>
            </div>
            <p class="error-message" v-if="validation.email.errorMessage">
              {{ validation.email.errorMessage }}
            </p>
          </div>

          <div class="form-group">
            <div class="input-field-wrapper password-input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <label for="password">Password</label>
              <div class="input-field-inner-wrapper">
                <div class="input-field">
                  <input type="password" name="password" id="password" data-rules='["required"]' v-model="password" />
                </div>
                <router-link to="/reset-password" class="cant-remember">Can't Remember</router-link>
              </div>
            </div>

            <p class="error-message" v-if="validation.password.errorMessage">
              {{ validation.password.errorMessage }}
            </p>
          </div>

          <div class="form-error" v-if="error">
            <div class="error">{{ error }}</div>
          </div>

          <button type="submit" class="">Login</button>
        </form>
      </div>
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';

export default {
  name: 'LoginForm',
  mixins: [formValidation],
  data() {
    return {
      validation: {
        inputElements: [],
        email: {},
        password: {},
      },
      email: '',
      password: '',
    };
  },
  mounted() {
    /* if (typeof this.$route.query.redirect != 'undefined') {
      this.$store.dispatch('setAfterLoginRedirectPath', this.$route.query.redirect);
    } */
    this.validation.inputElements = [...this.$el.querySelectorAll('input[data-rules]')];
    this.initValidation();
  },
  methods: {
    send() {
      this.error = null;
      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.formState = null;
          let redirectPath = {
            'path' : '/addresses'
            };

          if (this.$store.state.afterLoginRedirect != null) {
            redirectPath = this.$store.state.afterLoginRedirect;
          }

          this.$router.push({ path: redirectPath.path, query: redirectPath.query });
        })
        .catch((error) => {
            if(typeof error === 'object')
            {
                this.error = 'Something went wrong, Please try agian.';
            }
            else {
                this.error = error;
            }
            this.formState = null;
        });
    },
  },
};
</script>
