<template>
  <div class="auth-form-view">
    <div class="container l">
      <div class="auth-form login-form">
        <div class="page-message" v-if="isGettingQuote()">
          Please login to get a quote.
        </div>
        <div class="page-success" v-if="success">
          {{ success }}
        </div>
        <div class="page-error" v-if="error">
          {{ error }}
        </div>
        <div class="tabs">
          <div><router-link to="/login">Login</router-link></div>
          <div><router-link to="/register">Register</router-link></div>
        </div>

        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  name: "LoginView",
  data() {
    return {
      success: '',
      error: '',
    };
  },
  components: {
    LoginForm,
  },
  mounted() {
    this.success = this.$route.query['success'];
    this.error = this.$route.query['error'];
  },
  methods : {
    isGettingQuote() {
      return this.$store.state.afterLoginRedirect != null && this.$store.state.afterLoginRedirect.path == '/quote';
    }
  }
};
</script>

<style lang="scss">
@import "../scss/partials/variables.scss";
@import "../scss/partials/auth-form-view.scss";
</style>